import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import axios from "axios";
// import { useHistory } from "react-router-dom";
import "./styles/LoginPage.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
//   const history = useHistory()
  const formProps = useSpring({
    opacity: formSubmitted ? 0 : 1,
    transform: formSubmitted ? "translateY(-100%)" : "translateY(0%)",
  });

  const successProps = useSpring({
    opacity: formSubmitted ? 1 : 0,
    transform: formSubmitted ? "translateY(0%)" : "translateY(100%)",
  });

  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://comrex-app.herokuapp.com/api/auth/login", {
        email,
        password,
      });

      if (response.status === 200) {
        console.log('before here is ')
        window.location.href = "/private";
        
      }
    } catch (err) {
        console.log('here is error',err)
    //   setError(err.response.data.message);
    }
    setSuccess(!success);
  };

  return (
    <>
     <div className="login-page-container">
      <animated.form
        onSubmit={handleSubmit}
        style={formProps}
        className="login-form"
      >
        <h1 className="login-header">Login</h1>
        <label htmlFor="email" className="login-label">
        email
        </label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <label htmlFor="password" className="login-label">
          Password
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button type="submit" className="login-button">
          {formSubmitted ? "Logging in..." : "Login"}
        </button>
      </animated.form>
      <animated.div style={successProps} className="success-message">
        <p >Login successful!</p>
      </animated.div>
    </div>
    </>
  )
}

export default LoginPage;